import React from 'react'
import { string, bool } from 'prop-types'
import { white, secondaryColor, primaryColor } from 'styles/colors'
import styled from 'styled-components'
import * as spacing from 'styles/spacing'

const getFill = props => {
  if (props.white) return white
  if (props.brand) return primaryColor
  if (props.accent) return secondaryColor
  return 'currentColor'
}

const getSpacing = ({ small, medium }) => {
  if (small) return spacing.medium
  if (medium) return spacing.xLarge
  return spacing.large
}

class Icon extends React.Component {
  state = {
    IconComponent: null
  }

  loadIcon = () => {
    return import(`../assets/icons/${this.props.icon}.svg`).then(icon => {
      const { default: IconComponent } = icon
      if (this.unmounted) return
      this.setState({ IconComponent })
    }).catch(error => {
      console.log(error)
    })
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.icon !== this.props.icon) {
      console.log(this.props.icon)
      await this.loadIcon()
    }
  }

  async componentDidMount() {
    await this.loadIcon()
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  render() {
    const { icon, ...rest } = this.props
    const { IconComponent } = this.state

    if (!IconComponent) return null

    const Container = styled(({ white, accent, brand, light, small, medium, ...rest }) => <IconComponent {...rest} />)`
      display: inline-block;
      fill: ${getFill};
      width: ${getSpacing};
      height: ${getSpacing};
      vertical-align: top;
    `

    return <Container {...rest} />
  }
}

Icon.propTypes = {
  icon: string.isRequired,
  white: bool,
  light: bool,
  accent: bool,
  brand: bool,
  small: bool,
  medium: bool
}

export default Icon
