import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { array, bool, string } from 'prop-types'
import { stripUnit, rgba } from 'polished'

import * as spacing from 'styles/spacing'
import Icon from 'components/Icon'
import { grey, borderColor, offWhite, primaryColor } from 'styles/colors'
import { fontSizes, fontWeights } from 'styles/typography'

const Container = styled.div`
  position: relative;

  & + & {
    border-top: 1px solid ${borderColor};
  }
`

const Trigger = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.medium} ${spacing.large};
  width: 100%;
  text-align: left;
  align-items: center;
  font-weight: ${fontWeights.medium};
  cursor: pointer;
  text-transform: uppercase;
  font-size: ${fontSizes.small};
`

const Menu = styled.div`
  position: relative;
  background-color: ${offWhite};
  display: flex;
  flex-wrap: wrap;
  padding: ${spacing.small} 0;

  &:after {
    content: "";
    position: absolute;
    top: ${spacing.medium};
    bottom: ${spacing.medium};
    width: 1px;
    background-color: ${borderColor};
    left: 50%;
  }
`

const MenuItem = styled(Link)`
  display: block;
  width: 50%;
  text-align: left;
  color: ${grey};
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  padding: ${stripUnit(spacing.small) * 1.5 + 'px'} ${spacing.large};
`

const Badge = styled.span`
  background-color: ${rgba(primaryColor, 0.15)};
  color: ${primaryColor};
  text-transform: uppercase;
  padding: 0 ${spacing.tiny};
  border-radius: ${spacing.tiny};
  font-size: ${fontSizes.tiny};
  margin-left: ${spacing.small};
`

const MobileNavItem = ({ label, items, link, isNew, ...rest }) => {
  const [visibility, setVisibility] = useState(false)
  return (
    <Container {...rest}>
      {items ? (
        <Trigger
          onClick={() => { setVisibility(!visibility) }}>
          {label}
          {items &&
            <Icon
              icon={visibility ? 'minus-small' : 'plus-small'}
              brand
            />
          }
        </Trigger>
      ) : (
        <Trigger
          as={Link}
          to={link}
        >
          {label}
          {isNew && <Badge>New</Badge>}
        </Trigger>
      )}
      {items && visibility ? (
        <Menu>
          {items.map((item, i) =>
            <MenuItem
              key={i}
              to={item.link}
            >
              <span>{item.label}</span>
            </MenuItem>
          )}
        </Menu>
        ) : null
      }
    </Container>
  )
}

MobileNavItem.propTypes = {
  label: string.isRequired,
  link: string,
  items: array,
  isNew: bool
}

export default MobileNavItem
