import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { array, string, bool } from 'prop-types'
import { stripUnit, mix, rgba } from 'polished'

import * as spacing from 'styles/spacing'
import { offWhite, grey, black, greyDark, borderColor, primaryColor } from 'styles/colors'
import { animationTime, animationCurve, navHeight } from 'styles/global'
import { fontSizes, fontWeights } from 'styles/typography'
import { media } from 'styles/media'
import Icon from 'components/Icon'
import Wrapper from 'components/Wrapper'

const Container = styled.div`
  position: relative;
  margin-right: ${spacing.large};

  ${media.large`
    margin-right: ${spacing.xLarge};
  `};
`

const Trigger = styled(Link)`
  position: relative;
  z-index: 10; /* Position above parent's :before element */
  color: ${greyDark};
  height: ${navHeight};
  display: flex;
  align-items: center;
  transition: color ${animationTime} ${animationCurve};
  color: ${grey};
  text-transform: uppercase;
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.small};

  ${Container}:hover & {
    color: ${primaryColor};
  }
`

const Dropdown = styled.div`
  position: fixed;
  top: ${navHeight};
  background: ${mix(0.02, black, offWhite)};
  left: 0;
  width: 100%;
  padding: ${spacing.large};
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};
  z-index: 10;

  ${Container}:hover & {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
`

const DropdownInner = styled.div`
  display: flex;
  justify-content: center;
`

const DropdownItem = styled(Link)`
  display: block;
  color: ${grey};
  font-weight: ${fontWeights.medium};
  transition: all ${animationTime} ${animationCurve};

  &.${'active'},
  &:hover {
    color: ${primaryColor};
  }

  & + & {
    margin-left: ${spacing.large};
    padding-left: ${spacing.large};
    border-left: 1px solid ${borderColor};

    ${media.large`
      margin-left: ${spacing.xLarge};
      padding-left: ${spacing.xLarge};
    `};
  }
`

const Badge = styled.span`
  background-color: ${rgba(primaryColor, 0.15)};
  color: ${primaryColor};
  text-transform: uppercase;
  padding: 0 ${spacing.tiny};
  border-radius: ${spacing.tiny};
  font-size: ${fontSizes.tiny};
  margin-left: ${spacing.small};
`

const DropdownIcon = styled(Icon)`
  margin-left: ${stripUnit(spacing.tiny) * 0.5 + 'px'};
  opacity: 0.85;
`

const NavItem = ({ label, isNew, link, items }) => (
  <Container>
    {items ? (
      <Trigger
        as='button'
      >
        {label}
        <DropdownIcon icon="dropdown" />
      </Trigger>
    ) : (
      <Trigger
        as={Link}
        to={link}
      >
        {label}
        {isNew && <Badge>New</Badge>}
      </Trigger>
    )}
    {items && (
      <Dropdown>
        <Wrapper>
          <DropdownInner>
            {items.map((item, i) =>
              <DropdownItem
                key={i}
                to={item.link}
                activeClassName='active'
              >
                {item.label}
              </DropdownItem>
            )}
          </DropdownInner>
        </Wrapper>
      </Dropdown>
    )}
  </Container>
)

NavItem.propTypes = {
  label: string.isRequired,
  link: string,
  isNew: bool,
  items: array
}

export default NavItem
