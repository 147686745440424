import React from 'react'
import styled from 'styled-components'

import Wrapper from 'components/Wrapper'
import { H3 } from 'styles/typography'
import { white, greyDark } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

const Container = styled.div`
  text-align: center;
  padding: ${spacing.large};
  background-color: ${white};
  border: 10px solid ${greyDark};

  ${media.medium`
    padding: ${spacing.xLarge};
    border-width: 13px;
  `};

  ${media.large`
    border-width: 16px;
  `};
`

const HealthWarning = () => (
  <Container>
    <Wrapper>
      <H3>Tobacco use causes harm to your health and is addictive.</H3>
    </Wrapper>
  </Container>
)

export default HealthWarning
