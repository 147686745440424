import React from 'react'
import styled, { css } from 'styled-components'
import { string, node, bool } from 'prop-types'

import { verticalPadding } from 'styles/global'
import { borderColor, offWhite } from 'styles/colors'
import { media } from 'styles/media'

const Container = styled.section`
  width: 100%;
  ${verticalPadding`2.5rem`};

  ${props =>
    props.flushTop &&
    css`
      padding-top: 0 !important;
    `};

  ${props =>
    props.flushBottom &&
    css`
      padding-bottom: 0 !important;
    `};

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid ${borderColor};
    `};

  ${props =>
    props.altStyle &&
    css`
      background-color: ${offWhite};
    `};

  ${media.small`
    ${verticalPadding`3.5rem`};
  `};

  ${media.medium`
    ${verticalPadding`4.5rem`};
  `};

  ${media.large`
    ${verticalPadding`5.5rem`};
  `};
`

const Block = styled.div`
  position: relative;

  & + & {
    margin-top: 1.5rem;

    ${media.small`
      margin-top: 2rem;
    `};

    ${media.medium`
      margin-top: 2.75rem;
    `};

    ${media.large`
      margin-top: 3.5rem;
    `};
  }
`

const Section = ({ children, borderTop, alt, id, flushTop, flushBottom, className }) => (
  <Container
    className={className}
    id={id}
    altStyle={alt}
    flushTop={flushTop}
    flushBottom={flushBottom}
    borderTop={borderTop}
  >
    {children}
  </Container>
)

Section.propTypes = {
  children: node.isRequired,
  className: string,
  id: string,
  flushTop: bool,
  flushBottom: bool,
  alt: bool,
  borderTop: bool
}

export const SectionBlock = ({ children, className }) => <Block className={className}>{children}</Block>

SectionBlock.propTypes = {
  children: node.isRequired,
  className: string
}

export default Section
