import React, { useState } from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { AuthConsumer } from 'contexts/Auth'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { white, borderColor } from 'styles/colors'
import { navHeightMobile } from 'styles/global'
import Icon from 'components/Icon'
import Button from 'components/button/Button'
import MobileNavItem from './MobileNavItem'
import Portal from 'components/Portal'

const Toggle = styled.button`
  padding: ${spacing.small};
  margin: -${spacing.small};

  ${media.medium`
    display: none;
  `};
`

const Overlay = styled.div`
  position: fixed;
  top: ${navHeightMobile};
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${white};
  padding-bottom: ${spacing.large};
  z-index: 500;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: inset 0 1px 0 ${borderColor};
  display: ${props => (props.visible ? 'block' : 'none')};

  ${media.medium`
    display: none;
  `};
`

const ButtonWrap = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.small};
  margin-bottom: ${spacing.large};
  width: 260px;
`

const Action = styled.button`
  text-align: center;
  width: 100%;
  display: block;
`

const MobileNav = ({ onToggle }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <Helmet
        bodyAttributes={{ [`class`]: expanded ? 'is-clipped' : '' }}
      />
      <Toggle
        onClick={() => {
          setExpanded(!expanded)
          if (onToggle) onToggle(!expanded)
        }}
      >
        <Icon
          icon={expanded ? 'close' : 'hamburger'}
          grey='true'
        />
      </Toggle>
      <Portal selector={'mobile-nav-portal'}>
        <Overlay visible={expanded}>
          <MobileNavItem
            label='FAQs'
            link='/faq'
          />
          <MobileNavItem
            label='Contact Us'
            link='/contact-us'
          />
          <AuthConsumer>
            {value => {
              return (
                value.user ? (
                  <>
                    <ButtonWrap>
                      <Button
                        onClick={() => { value.showModal('/account') }}
                        theme="primary"
                        fullwidth
                      >
                        My account
                      </Button>
                    </ButtonWrap>
                    <Action
                      onClick={value.logout}
                    >
                      Logout
                    </Action>
                  </>
                ) : (
                  <ButtonWrap>
                    <Button
                      onClick={value.login}
                      theme='primary'
                      fullwidth
                    >
                      Login/Register
                    </Button>
                  </ButtonWrap>
                )
              )
            }}
          </AuthConsumer>
        </Overlay>
      </Portal>
    </>
  )
}

MobileNav.propTypes = {
  onToggle: func
}

export default MobileNav
