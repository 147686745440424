import React from 'react'
import styled from 'styled-components'

import { verticalPadding } from 'styles/global'
import { greyLight, white } from 'styles/colors'
import { media } from 'styles/media'
import { fontSizes, H4 } from 'styles/typography'
import Wrapper from 'components/Wrapper'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import * as spacing from 'styles/spacing'
import FooterLink from './FooterLink'

const currentYear = new Date().getFullYear()

const Container = styled.div`
  background-color: ${white};
  ${verticalPadding`3rem`};

  ${media.small`
    ${verticalPadding`4rem`};
  `};

  ${media.medium`
    ${verticalPadding`5.5rem`};
  `};
`

const List = styled.div`
  padding-right: ${spacing.large};
`

const Heading = styled(H4)`
  display: block;
  margin-bottom: ${spacing.small};

  ${media.medium`
    margin-bottom: ${spacing.medium};
  `};
`

const Disclaimer = styled.p`
  color: ${greyLight};
  font-size: ${fontSizes.small};
`

const PaymentIcon = styled.img`
  margin-right: ${spacing.tiny};
  width: 48px;

  ${media.medium`
    width: 56px;
  `};

  ${media.large`
    width: 64px;
  `};
`

const Footer = () => (
  <Container>
    <Wrapper>
      <GridRow>

        <GridItem
          xs="1-2"
          sm="1-3"
          md="1-4"
        >
          <List>
            <Heading>Information</Heading>
            <FooterLink
              to="/faq"
              label="FAQs"
            />
            <FooterLink
              to="/contact-us"
              label="Contact Us"
            />
          </List>
        </GridItem>

        <GridItem
          xs="1-2"
          sm="1-3"
          md="1-4"
        >
          <List>
            <Heading>Legal</Heading>
            <FooterLink
              label="Privacy Policy"
              to="/pages/privacy-policy"
            />
            <FooterLink
              label="Cookie Policy"
              to="/pages/cookies"
            />
            <FooterLink
              label="Terms & Conditions"
              to="/pages/terms-conditions"
            />
            <FooterLink
              label="Warranty Guidelines"
              to="/pages/warranty-guidelines"
            />
          </List>
        </GridItem>

        <GridItem
          xs="1-2"
          sm="1-3"
          md="1-4"
        >
          <PaymentIcon src={require(`assets/images/payment/mastercard.png`)} />
          <PaymentIcon src={require(`assets/images/payment/visa.png`)} />
        </GridItem>

        <GridItem
          xs="1-1"
          md="1-4"
        >
          <Disclaimer>© Imperial Tobacco Company Ltd {currentYear} All rights reserved.</Disclaimer>
        </GridItem>

      </GridRow>
    </Wrapper>
  </Container>
)

export default Footer
