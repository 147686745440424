import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { GlobalStyle } from 'styles/global'
import { AuthProvider } from 'contexts/Auth'
import Header from 'components/Header'
import Warning from 'components/HealthWarning'
import Footer from 'components/Footer/index'

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Helmet
      title="Tobacco Heating Products - glo"
      meta={[
        {
          name: 'description',
          content:
            'glo - Discover a new way to experience tobacco. glo heats tobacco rather than burning it, it produces no ash and less odor. glo device is simple and intuitive – just one tobacco heater device to charge and one button to operate. Find where to buy glo in Canada - visit glo.ca'
        }
      ]}
    />
    <AuthProvider>
      <Header />
      <div>{children}</div>
      <Warning />
      <Footer />
    </AuthProvider>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
