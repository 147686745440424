import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { node, bool, string } from 'prop-types'
import { mix, rgba, stripUnit } from 'polished'
import { Link } from 'gatsby'

import { media } from 'styles/media'
import { primaryColor, grey, red, white, black } from 'styles/colors'
import { animationTime, animationCurve, inputHeight } from 'styles/global'
import * as spacing from 'styles/spacing'
import Icon from 'components/Icon'
import SmallCaps from 'components/SmallCaps'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${inputHeight};
  border-radius: ${spacing.large};
  padding: 0 ${stripUnit(spacing.medium) * 1.25 + 'px'};
  cursor: pointer;
  text-align: center;
  transition: all ${animationTime} ${animationCurve};

  ${media.medium`
    padding: 0 ${spacing.large};
  `};

  ${media.large`
    padding: 0 ${spacing.xLarge};
  `};

  ${props =>
    props.fullwidth &&
    css`
      display: flex;
      width: 100%;
    `}

  ${props =>
    props.theme === 'primary' &&
    css`
      background-color: ${primaryColor};
      color: ${white};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, primaryColor, black)};
      }
    `}

  ${props =>
    props.theme === 'secondary' &&
    css`
      background-color: ${grey};
      color: ${white};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, grey, black)};
      }
    `}

  ${props =>
    props.theme === 'tertiary' &&
    css`
      color: ${white};
      background-color: transparent;
      border: 2px solid ${rgba(white, 0.4)};

      &:hover,
      &:focus,
      &:active {
        background-color: ${white};
        color: ${primaryColor};
        border-color: ${white};
      }
    `}

  ${props =>
    props.theme === 'negative' &&
    css`
      color: ${white};
      background-color: ${red};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, red, black)};
      }
    `}

  ${props =>
    props.size === 'small' &&
    css`
      height: ${stripUnit(inputHeight) * 0.875 + 'px'};
      padding: 0 ${spacing.medium};

      ${media.medium`
        padding: 0 ${stripUnit(spacing.medium) * 1.25 + 'px'};
      `};

      ${media.large`
        padding: 0 ${spacing.large};
      `};
    `}

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
`

const Spinner = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  animation: ${rotate} 0.75s linear infinite;
`

const StyledIcon = styled(Icon)`
  margin-left: ${spacing.small};
  flex-shrink: 0;
`

const Label = styled(SmallCaps)`
  display: flex;
  align-items: center;
  transition: opacity ${animationTime} ${animationCurve};

  ${props =>
    props.isloading &&
    css`
      opacity: 0;
    `};
`

const getElement = props => {
  if (props.to) return ({ fullwidth, ...rest }) => <Link {...rest} />
  if (props.href) return 'a'
  return 'button'
}

const Button = ({ children, theme, size, icon, isloading, fullwidth, type = 'button', ...rest }) => (
  <Container
    fullwidth={fullwidth}
    theme={theme}
    size={size}
    isloading={isloading}
    disabled={isloading}
    as={getElement(rest)}
    type={type}
    {...rest}
  >
    {isloading && <Spinner icon="spinner" />}
    <Label isloading={isloading}>
      {children}
      {icon && <StyledIcon icon={icon} />}
    </Label>
  </Container>
)

Button.propTypes = {
  children: node.isRequired,
  theme: string,
  size: string,
  fullwidth: bool,
  isloading: bool,
  icon: string,
  type: string
}

export default Button
