// Primary

export const orange = '#e65400'
export const slate = '#006FD6'

// Greys

export const greyDark = '#222'
export const grey = '#555'
export const greyLight = '#999'
export const greyLightest = '#ddd'
export const offWhite = '#f2f2f2'

// Misc

export const white = '#fff'
export const black = '#000'
export const red = '#FF535C'
export const green = '#93c500'

// Set colors

export const primaryColor = orange
export const secondaryColor = slate
export const backgroundColor = offWhite
export const borderColor = greyLightest
