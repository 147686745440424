import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { Link } from 'gatsby'

import { animationTime, animationCurve } from 'styles/global'
import { fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { greyDark } from 'styles/colors'

const Container = styled(Link)`
  display: block;
  font-size: ${fontSizes.small};
  transition: color ${animationTime} ${animationCurve};

  & + & {
    margin-top: ${spacing.small};
  }

  &:hover,
  &:focus {
    color: ${greyDark};
  }
`

const FooterLink = ({ label, to, ...rest }) => (
  <Container to={to}>
    {label}
  </Container>
)

FooterLink.propTypes = {
  label: string.isRequired,
  to: string.isRequired
}

export default FooterLink
