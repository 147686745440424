import { css } from 'styled-components'
import { media } from 'styles/media'

export default css`

  ${media.small`

    .u-push-1-8-sm {
      margin-left: 12.5% !important;
    }

    .u-push-1-6-sm {
      margin-left: 16.6666% !important;
    }

  `};

  ${media.medium`

    .u-push-0-md {
      margin-left: 0 !important;
    }

    .u-push-1-6-md {
      margin-left: 16.6666% !important;
    }

  `};

  ${media.large`

    .u-push-1-12-lg {
      margin-left: 8.3333% !important;
    }

    .u-push-1-6-lg {
      margin-left: 16.6666% !important;
    }

  `};
`
