import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import * as spacing from 'styles/spacing'
import { white } from 'styles/colors'
import { media, mediaDown } from 'styles/media'
import NavItem from './NavItem'
import Account from './Account'
import Wrapper from 'components/Wrapper'
import MobileNav from 'components/MobileNav/index'
import Logo from 'assets/images/logo.svg'
import { navHeight, navHeightMobile } from 'styles/global'

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  height: ${navHeightMobile};

  ${media.medium`
    height: ${navHeight};
  `};
`

const Inner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

const LogoWrap = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.medium`
    margin-right: ${spacing.xLarge};
  `};

  ${media.large`
    margin-right: ${spacing.xxLarge};
  `};

  ${media.xLarge`
    margin-right: ${spacing.xxxLarge};
  `};

  ${mediaDown.medium`
    margin-left: ${spacing.medium};
  `};
`

const StyledLogo = styled(Logo)`
  width: 45px;
  height: 38px;

  ${media.medium`
    width: 57px;
    height: 48px;
  `};
`

const List = styled.div`
  display: flex;

  ${mediaDown.medium`
    display: none;
  `};
`

const Nav = () => (
  <Container>
    <Wrapper>
      <Inner>
        <MobileNav />
        <LogoWrap to='/'>
          <StyledLogo />
        </LogoWrap>
        <List>
          <NavItem
            label='FAQs'
            link='/faq'
          />
          <NavItem
            label='Contact Us'
            link='/contact-us'
          />
        </List>
        <Account />
      </Inner>
    </Wrapper>
  </Container>
)

export default Nav
