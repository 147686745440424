import React from 'react'
import styled from 'styled-components'
import { stripUnit } from 'polished'
import { AuthConsumer } from 'contexts/Auth'

import * as spacing from 'styles/spacing'
import { white, grey, greyDark, borderColor } from 'styles/colors'
import { animationTime, animationCurve, navHeight, boxShadow, square } from 'styles/global'
import { fontSizes, fontWeights } from 'styles/typography'
import { mediaDown } from 'styles/media'
import Icon from 'components/Icon'

const Container = styled.div`
  position: relative;
  margin-left: auto;

  ${mediaDown.medium`
    display: none;
  `};

  &:hover:before {
    content: "";
    display: block;
    position: absolute;
    left: -30px;
    right: -30px;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }
`

const Trigger = styled.button`
  position: relative;
  z-index: 10;
  display: flex;
  text-align: left;
  align-items: center;
  text-transform: uppercase;
  height: ${navHeight};
`

const Dropdown = styled.div`
  background-color: ${white};
  border: 1px solid ${borderColor};
  margin-top: -${stripUnit(spacing.small) * 1.5 + 'px'};
  top: 100%;
  right: -${spacing.large};
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  padding: ${spacing.large};
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};
  width: 190px;
  z-index: 10;
  color: ${grey};
  ${boxShadow};

  ${Container}:hover &,
  ${Container}:focus & {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
`

const Item = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  transition: color ${animationTime} ${animationCurve};

  &:hover {
    color: ${greyDark};
  }

  & + & {
    margin-top: ${spacing.medium};
  }
`

const Avatar = styled.div`
  color: ${white};
  background-color: #009696;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.bold};
  ${square('30px !important')};
`

const Account = () => (
  <Container>
    <Trigger>
      <AuthConsumer>
        {value => {
          return (
            value.user ? (
              <Avatar>{value.user.firstName.charAt(0)}{value.user.lastName.charAt(0)}</Avatar>
            ) : (
              <Icon
                icon="account"
                medium
              />
            )
          )
        }}
      </AuthConsumer>
    </Trigger>
    <Dropdown>
      <AuthConsumer>
        {value => {
          return (
            value.user ? (
              <>
                <Item onClick={() => { value.showModal('/account') }}>My account</Item>
                <Item onClick={value.logout}>Logout</Item>
              </>
            ) : (
              <Item onClick={value.login}>Login/Register</Item>
            )
          )
        }}
      </AuthConsumer>
    </Dropdown>
  </Container>
)

export default Account
