import styled, { css } from 'styled-components'
import { stripUnit, rgba } from 'polished'

import { media } from './media'
import { greyDark, primaryColor, grey, greyLight } from './colors'

// Font family
export const fontFamily =
  "Gotham SSm A, Gotham SSm B, Gotham, Noto Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"

// Font weights
export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
}

export const lineHeights = {
  base: 1.5,
  heading: 1.25,
  tight: 1.15
}

// Font sizes
export const fontSizes = {
  regular: '16px',
  small: '14px',
  tiny: '12px'
}

export const H1 = styled.h1`
  font-weight: ${fontWeights.medium};
  color: ${greyDark};
  line-height: ${lineHeights.heading};
  font-size: 2rem;
  font-family: ${fontFamily};
  margin-bottom: 0.2em;
  ${media.small`font-size: 2.3rem`};
  ${media.medium`font-size: 2.6rem`};
  ${media.large`font-size: 3.1rem`};
`

export const H2 = styled.h2`
  font-weight: ${fontWeights.medium};
  color: ${greyDark};
  line-height: ${lineHeights.heading};
  font-size: 1.6rem;
  font-family: ${fontFamily};
  margin-bottom: 0.4em;
  ${media.small`font-size: 1.75rem`};
  ${media.medium`font-size: 2rem`};
  ${media.large`font-size: 2.25rem`};
`

export const H3 = styled.h3`
  font-weight: ${fontWeights.medium};
  color: ${greyDark};
  line-height: ${lineHeights.heading};
  font-size: 1.3rem;
  font-family: ${fontFamily};
  margin-bottom: 0.5em;
  ${media.small`font-size: 1.4rem`};
  ${media.medium`font-size: 1.55rem`};
  ${media.large`font-size: 1.7rem`};
`

export const H4 = styled.h4`
  font-weight: ${fontWeights.medium};
  color: ${greyDark};
  line-height: ${lineHeights.heading};
  font-size: 1.1rem;
  font-family: ${fontFamily};
  margin-bottom: 0.5em;
  ${media.small`font-size: 1.2rem`};
  ${media.medium`font-size: 1.25rem`};
  ${media.large`font-size: 1.3rem`};
`

export const H5 = styled.h5`
  font-weight: ${fontWeights.medium};
  font-size: 1em;
  color: ${greyDark};
  line-height: ${lineHeights.heading};
`

export const Lead = styled.p`
  font-size: 1.1em;

  ${media.small`
    font-size: 1.15em;
  `};

  ${media.medium`
    font-size: 1.2em;
  `};

  ${media.large`
    font-size: 1.25em;
  `};
`

export const Dark = styled.span`
  color: ${greyDark};
`

export const Muted = styled.span`
  color: ${greyLight};
`

export const Brand = styled.span`
  color: ${primaryColor};
`

export const Underline = styled.span`
  text-decoration: underline;
`

export default css`

  body {
    font-family: ${fontFamily};
    font-weight: ${fontWeights.regular};
    line-height: ${lineHeights.base};
    color: ${grey};
    font-size: ${stripUnit(fontSizes.regular) - 1 + 'px'};
    -webkit-font-smoothing: subpixel-antialiased;

    ${media.medium`
      font-size: ${fontSizes.regular};
    `};

    ${media.large`
      font-size: ${stripUnit(fontSizes.regular) + 1 + 'px'};
    `};
  }

  small {
    font-size: ${fontSizes.small};
  }

  strong {
    font-weight: ${fontWeights.bold};
  }

  p {
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.medium`
      margin-bottom: 1.5em;
    `};
  }

  p a {
    color: ${primaryColor};
    border-bottom: 1px solid ${rgba(primaryColor, 0.2)};
    transition: border-color 0.3s ease-in-out;

    &:hover,
    &:focus {
      border-color: ${rgba(primaryColor, 0.4)};
    }
  }
`
