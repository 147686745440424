import React from 'react'
import styled from 'styled-components'

import Nav from 'components/Nav'
import { white } from 'styles/colors'
import { media } from 'styles/media'
import { animationTime, animationCurve, boxShadow } from 'styles/global'

const Container = styled.div`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${white};
  z-index: 600;
  ${boxShadow};

  ${media.medium`
    position: sticky;
    transition: transform ${animationTime} ${animationCurve};
  `};
`

const Header = () => (
  <Container>
    <Nav />
  </Container>
)

export default Header
