/* eslint no-undef: 0 */

import React, { Component } from 'react'
import { node } from 'prop-types'

const AuthContext = React.createContext({
  loading: true
})

class AuthProvider extends Component {
  static propTypes = {
    children: node.isRequired
  }

  state = {
    loading: true,
    userLoading: true,
    user: null,
    softVerifiedUser: null,
    province: null,
    storeName: 'Glo'
  }

  queryParams = null

  componentDidMount() {
    if (window !== 'undefined') {
      this.queryParams = window.location.search
        .substr(1)
        .split('&')
        .map(function(qsPair) { return qsPair.split('=') })
        .reduce(function(acc, keyValue) {
          const key = keyValue[0]
          const value = keyValue[1] || true

          acc[key] = value
          return acc
        }, {})
    }

    if (localStorage.user) this.setState({ user: JSON.parse(localStorage.user) })
    if (localStorage.softVerifiedUser) this.setState({ softVerifiedUser: JSON.parse(localStorage.softVerifiedUser) })
    if (localStorage.province) this.setState({ province: localStorage.province })

    const script = document.createElement('script')

    script.src = `${process.env.GATSBY_AUTH_SDK_URL}/main.js`
    script.async = true

    script.onload = () => {
      window.ImperialAuth.setStore({
        logo: 'https://cdn.shopify.com/s/files/1/2727/5392/files/logo.png?12776652579441201003',
        name: 'glo',
        website: 'glo.ca',
        colors: {
          primary: '#e65400',
          secondary: '#e65400'
        },
        phone: '1-866-750-5893',
        email: 'support@glo.ca',
        unverifiedUserToken: this.queyrParams && this.queryParams.unverifiedUserToken,
        patronscanUserToken: this.queyrParams && this.queryParams.patronscanUserToken
      })

      window.ImperialAuth.iframe.addEventListener('load', this.initialAuthCheck)
    }

    this.setState({ loading: false })

    document.head.appendChild(script)
  }

  initialAuthCheck = async () => {
    const _this = this

    const [{ softVerifiedUser }, { loggedIn }] = await Promise.all([
      window.ImperialAuth.getSoftVerifiedUser(),
      window.ImperialAuth.checkLoggedIn()
    ])

    if (loggedIn) {
      ImperialAuth.getUserState().then(function(res) {
        _this.setState({
          user: res.user,
          province: res.user.province,
          userLoading: false
        })
        localStorage.setItem('user', JSON.stringify(res.user))
        localStorage.setItem('province', res.user.province)
      })
    } else if (softVerifiedUser) {
      // Show signup modal if coming from /register, even if user is soft AV'ed
      this.setState({
        softVerifiedUser: softVerifiedUser,
        province: softVerifiedUser.province,
        userLoading: false
      })

      localStorage.setItem('softVerifiedUser', JSON.stringify(softVerifiedUser))
      localStorage.setItem('province', softVerifiedUser.province)

      if (this.queryParams['register'] === 'true') this.promptAuth()
    } else {
      this.promptAuth()
      this.setState({ userLoading: false })
    }
  }

  promptAuth = async () => {
    const authMethod = this.queryParams['redeem'] === 'true' || this.queryParams['register'] === 'true' ? ImperialAuth.signup : ImperialAuth.promptSoftVerification
    let { user, token, softVerifiedUser } = await authMethod()

    let province

    if (softVerifiedUser) {
      province = softVerifiedUser.province
      localStorage.setItem('softVerifiedUser', JSON.stringify(softVerifiedUser))
      localStorage.setItem('province', softVerifiedUser.province)
    } else if (user) {
      province = user.province
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('province', user.province)
      if (token) localStorage.setItem('token', token)
      softVerifiedUser = this.softVerifiedUserWithUser(user)
      localStorage.setItem('softVerifiedUser', JSON.stringify(softVerifiedUser))
    }

    this.setState({
      user: user,
      softVerifiedUser: softVerifiedUser,
      province: province
    })
  }

  login = async () => {
    ImperialAuth.login().then(response => {
      const { user, token } = response
      this.setState({ user: user, province: user.province })
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('province', user.province)
      localStorage.setItem('token', token)
    })
  }

  signup = () => {
    ImperialAuth.signup().then(response => {
      const { user, token } = response
      this.setState({ user: user, province: user.province })
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('province', user.province)
      localStorage.setItem('token', token)
    })
  }

  logout = async () => {
    ImperialAuth.logout().then(response => {
      // response gives softVerifiedUser as a string
      this.setState({ user: null })

      localStorage.removeItem('user')
      localStorage.removeItem('token')
    })
  }

  softVerifiedUserWithUser = user => {
    const birthDate = new Date(user.dob)

    return {
      emailSubscription: user[`emailSubscription${this.state.storeName}`],
      province: user.province,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      dob: `${birthDate.getFullYear()}/${("0" + (birthDate.getMonth() + 1)).slice(-2)}/${("0" + (birthDate.getDay() + 1)).slice(-2)}`
    }
  }

  render() {
    let value = {}

    if (typeof window !== `undefined`) {
      value = {
        ...this.state,
        ...window.ImperialAuth,
        login: this.login,
        logout: this.logout,
      }
    }

    return <AuthContext.Provider value={value}>{this.props.children}</AuthContext.Provider>
  }
}

const { Consumer } = AuthContext

export {
  Consumer as AuthConsumer,
  AuthProvider
}

export default AuthContext
