import styled from 'styled-components'
import { media } from 'styles/media'
import { fontWeights } from 'styles/typography'

export default styled.span`
  font-weight: ${fontWeights.bold};
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.075em;

  ${media.medium`
    font-size: 13px;
  `};

  ${media.large`
    font-size: 14px;
  `};
`
